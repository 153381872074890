import { motion } from 'framer-motion';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import { useCart } from '../../contexts/cart-context';
import Button from '../shared/Button';

interface Price {
  id: string;
  currency: string;
  amount: number;
  product: {
    id: string;
  };
}

type Props = {
  image: FluidObject;
  description: string;
  sizes: string[];
  prices: Price[];
};

const ProductDetail = ({ image, description, sizes, prices }: Props) => {
  const cartContext = useCart();
  const price = prices[0];

  const addToCart = () => {
    cartContext.add(price.id);
  };

  return (
    <div className='product_detail'>
      <Img fluid={image} className='product_detail__image' />
      <p className='product_detail__description'>{description}</p>
      <div className='product_detail__sizes'>
        {sizes.map(size => (
          <motion.button
            key={size}
            type='button'
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className='product_detail__size'
          >
            {size.trim().toUpperCase()}
          </motion.button>
        ))}
      </div>
      <div className='product_detail__add_button_wrapper'>
        <Button onclick={addToCart} type='primary'>
          Add to cart
        </Button>
      </div>
    </div>
  );
};

export default ProductDetail;
