import { PageProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import React from 'react';
import ProductDetail from '../components/ProductDetail';
import SEO from '../components/seo';
import Title from '../components/shared/Title';
import '../styles/templates/product-page.scss';

interface ProductQuery {
  id: string;
  name: string;
  description: string;
  metadata: {
    category?: string;
    sizes?: string;
  };
  localFiles: { childImageSharp: { fluid: FluidObject } }[];
}

interface Price {
  id: string;
  currency: string;
  amount: number;
  product: {
    id: string;
  };
}

type PageContextType = {
  product: ProductQuery;
  prices: Price[];
};

const ProductPage = ({ pageContext }: PageProps<null, PageContextType>) => {
  const { product, prices } = pageContext;
  const images = product.localFiles.map(x => x.childImageSharp.fluid);
  const sizes = product.metadata.sizes?.split(',');

  return (
    <>
      <SEO title={`Products - ${product.name}`} />

      <Title>{product.name}</Title>
      <Title type='secondary'>{product.metadata.category}</Title>

      <ProductDetail
        image={images[0]}
        prices={prices}
        description={product.description}
        sizes={sizes || []}
      />
    </>
  );
};

export default ProductPage;
